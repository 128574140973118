import { CheckIcon, ChevronDownIcon, ChevronRightIcon, PlusIcon, XIcon } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { ValueAndLabel } from '../../types';
import { useConsultantContext } from '../../utils/contexts/consultant-context';
import { availableLevels, cn, skillSet } from '../../utils/helpers';
import DateHelper from '../../utils/helpers/date.helper';
import { useDeleteConsultant } from '../../utils/hooks/api/consultants/useDeleteConsultant';
import { useUpdateConsultant } from '../../utils/hooks/api/consultants/useUpdateConsultant';
import { useOffices } from '../../utils/hooks/api/offices/useOffices';
import { useRoles } from '../../utils/hooks/api/roles/useRoles';
import { useStatuses } from '../../utils/hooks/api/statuses/useStatuses';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import { Combobox } from '../ui/combo-box';
import { Command, CommandGroup, CommandItem, CommandList } from '../ui/command';
import useConfirm from '../ui/confirm-dialog';
import { DateSelector } from '../ui/date-selector';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../ui/hover-card';
import { Input } from '../ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { ResponsiveSelect } from '../ui/responsive-select';
import { Textarea } from '../ui/textarea';


function ConsultantCardInfoUpdate({
  handleEditMode,
  handleModalOpen,
}: {
  handleEditMode: () => void;
  handleModalOpen: () => void;
}) {
  const { parsedOffices } = useOffices();
  const { parsedStatuses } = useStatuses();
  const { parsedRoles } = useRoles();
  const { confirm } = useConfirm();
  const { consultant } = useConsultantContext();
  const { mutate: deleteConsultant } = useDeleteConsultant();
  const [consultantUpdateInfo, setConsultantUpdateInfo] = useState({
    ...consultant,
    availabilityDate: DateHelper.timestampToDateFormat(
      consultant.availabilityDate
    ),
  });
  
  const [consultantSkillSet, setConsultantSkillSet] = useState<typeof skillSet>(consultant.skills || [])
  const [skillSearch, setSkillSearch] = useState({ query: "", queryLower: "" })
  const allSkills = Object.keys(skillSet)
    .map((category) => { 
      return skillSet[category].map((value) => { 
        return { category, labelLower: value.label.trim().toLowerCase(), ...value }; 
      }) 
    })
    .flat()
    .sort((a, b) => { return a.label > b.label ? 1 : -1 });

  const [filteredSkillSet, setFilteredSkillSet] = useState(allSkills)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const finalizeDelete = async () => {
    const choice = await confirm({
      title: 'Delete consultant',
      description:
        'This will delete the information of this consultant permanently, you can’t undo this action. Are you sure?',
      destructiveLabel: 'Delete',
      cancelLabel: 'Cancel',
    });

    if (choice) {
      deleteConsultant(consultantUpdateInfo.id, {
        onSuccess: () => {
          handleEditMode();
        },
      });
    }
  };

  useEffect(() => {
    if (!parsedRoles) {
      return;
    }

    setConsultantUpdateInfo({
      ...consultantUpdateInfo,
      roles: parsedRoles
        .filter((role) => consultantUpdateInfo.roles?.includes(role.label))
        .map((role) => role.value),
    });
  }, [parsedRoles]);

  function handleDeselectAllRoles() {
    setConsultantUpdateInfo({
      ...consultantUpdateInfo,
      roles: [],
    });
  }

  function handleSelectRole(option: string) {
    if (
      !consultantUpdateInfo.roles?.some((value: string) => value === option)
    ) {
      setConsultantUpdateInfo({
        ...consultantUpdateInfo,
        roles: [...(consultantUpdateInfo.roles ?? []), option],
      });
    } else {
      setConsultantUpdateInfo({
        ...consultantUpdateInfo,
        roles: consultantUpdateInfo.roles.filter(
          (value: string) => value !== option
        ),
      });
    }
  }

  function updateConsultantSkillSet(updCategory: string, updSkill: ValueAndLabel) {
    var skillsToBeSelected = allSkills.filter( (fit) => fit.value === updSkill.value )
    
    if (skillsToBeSelected.length == 0) {
      skillsToBeSelected = [{ category: updCategory, labelLower: updSkill.label.trim().toLowerCase(), ...updSkill}]
    }

    let newConsultantSkills = { ...consultantSkillSet } 

    // Add all the skills with the same 'value' attribute as the one clicked into the newConsultantSkills
    skillsToBeSelected.forEach((each) => {
      const skill = {label: each.label, value: each.value}
      const category = each.category

      const categorySkills = newConsultantSkills[category] || [];
      const skillExists = categorySkills.some((s) => s.value === skill.value);

      const updatedSkills = skillExists
        ? categorySkills.filter((s) => s.value !== skill.value)
        : [...categorySkills, skill];

      newConsultantSkills = { ...newConsultantSkills, [category]: updatedSkills };

      // Removes categories that are empty
      newConsultantSkills = Object.fromEntries(
        Object.entries(newConsultantSkills).filter(([_, skills]) => skills.length > 0)
      );
    })

    setConsultantUpdateInfo((prevUpdateInfo) => ({
      ...prevUpdateInfo,
      skills: newConsultantSkills,
    }));

    setConsultantSkillSet(() => newConsultantSkills )
  }

  const { mutate: updateConsultant } = useUpdateConsultant();

  const saveConsultant = async () => {
    try {
      // @ts-ignore
      consultantUpdateInfo.location =
        consultantUpdateInfo.location.abbreviation;
      // @ts-ignore
      consultantUpdateInfo.color = consultantUpdateInfo.color.RGBCode;
      Object.keys(consultantUpdateInfo).forEach((key) => {
        // @ts-ignore
        if (consultantUpdateInfo[key] === null) {
          // @ts-ignore
          delete consultantUpdateInfo[key];
        }
      });
      const timestamp = DateHelper.dateToEpochTimestamp(
        consultantUpdateInfo.availabilityDate
      );
      if (!isNaN(timestamp)) {
        // @ts-ignore
        consultantUpdateInfo.availabilityDate = timestamp;
      }
      updateConsultant(consultantUpdateInfo, {
        onSuccess: (updatedConsultant) => {
          handleEditMode();
          setConsultantUpdateInfo({
            ...updatedConsultant,
            availabilityDate: DateHelper.timestampToDateFormat(
              updatedConsultant.availabilityDate
            ),
          });
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  function searchSkill(txt: string) {
    const txtLowerCase = txt.trim().toLowerCase()

    setSkillSearch({query: txt, queryLower: txtLowerCase})
    setFilteredSkillSet(allSkills.filter((fit) => { return fit.labelLower.includes(txtLowerCase) }))
  }

  function createAddSkillItem(skillSelected: boolean) {
    return (
      <CommandItem
        key={skillSearch.query}
        className={cn(
          'flex gap-2 px-4 py-2 items-center justify-between bg-primary-foreground cursor-pointer hover:bg-primary-100',
          skillSelected ? 'bg-primary-100' : 'bg-blue-400'
        )}
        value={skillSearch.query}
        onSelect={() => updateConsultantSkillSet("Others", {value: skillSearch.queryLower, label:skillSearch.query})}
      > 
        { !skillSelected && <PlusIcon className='w-4 text-primary-500' /> }
        { skillSelected ? skillSearch.query : "\"" + skillSearch.query + "\"" }
        { skillSelected && <CheckIcon className='w-4 text-primary-500' /> }
      </CommandItem>
    )
  }
  

  return (
    <div className='space-y-8'>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8'>
        <div className='grid items-center grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1 '>
            <label className='font-semibold text-left'>Name:</label>
          </div>
          <Input
            className='col-span-5 md:col-span-4'
            type='text'
            value={consultantUpdateInfo.name}
            placeholder='Name'
            name='name'
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                name: e.target.value,
              })
            }
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Level:</label>
          </div>

          <ResponsiveSelect
            name='Level'
            onValueChange={(option) => {
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                level: option,
              });
            }}
            options={availableLevels}
            value={consultantUpdateInfo.level}
            className='col-span-5 md:col-span-4'
          />
        </div>
        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1 '>
            <label className='font-semibold text-left'>Status:</label>
          </div>
          <ResponsiveSelect
            name='Status'
            useLabelAsSelected
            onValueChange={(option) => {
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                color: {
                  RGBCode: option,
                  name: option,
                  description: option,
                },
              });
            }}
            options={parsedStatuses}
            value={consultantUpdateInfo.color.RGBCode}
            className='col-span-5 md:col-span-4'
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Location:</label>
          </div>
          <ResponsiveSelect
            name='Office'
            onValueChange={(option) => {
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                location: {
                  abbreviation: option,
                  city: option,
                },
              });
            }}
            options={parsedOffices}
            value={consultantUpdateInfo.location.abbreviation}
            className='col-span-5 md:col-span-4'
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Role:</label>
          </div>
          <Combobox
            selectedOptions={consultantUpdateInfo.roles}
            selectName='Roles'
            useLabelAsSelected
            className='col-span-5 md:col-span-4 '
            handleDeselectAll={handleDeselectAllRoles}
            options={parsedRoles}
            selectOption={handleSelectRole}
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-5 md:col-span-1'>
            <label className='font-semibold text-left'>Available since:</label>
          </div>
          <DateSelector
            buttonLabel='Available since'
            buttonClasses='col-span-5 md:col-span-4'
            date={new Date(consultantUpdateInfo.availabilityDate)}
            selectDate={(date) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                availabilityDate: date!.toLocaleDateString('sv-SE'),
              })
            }
          />
        </div>

        <div className='grid items-center grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Clients:</label>
          </div>

          <Input
            className='col-span-5 md:col-span-4'
            type='text'
            defaultValue={consultantUpdateInfo.clients}
            placeholder='Enter clients'
            name='client'
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                clients: e.target.value,
              })
            }
          />
        </div>

        <div className='grid grid-cols-5 '>
          <div className='flex flex-row items-center col-span-5 md:col-span-1'>
            <label className='font-semibold text-left'>Leads:</label>
          </div>
          <Input
            className='col-span-5 md:col-span-4'
            type='text'
            defaultValue={consultantUpdateInfo.leads}
            placeholder='Enter leads'
            name='leads'
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                leads: e.target.value,
              })
            }
          />
        </div>

        <div className='grid md:grid-cols-5 items-baseline'>
          <div className='flex flex-row items-center col-span-2 md:col-span-1'>
            <label className='font-semibold text-left'>Skills:</label>
          </div>
          <Popover>
            <PopoverTrigger asChild className="col-span-4" >
              <Button
                variant="outline"
                role="combobox"
                aria-label="Select a skill category"
                className="flex justify-between text-black hover:bg-primary-foreground"
                onKeyUp={(event) => { event.preventDefault() }}
                onClick={() => { searchInputRef.current?.focus() }}
              >
                <input 
                  ref={searchInputRef}
                  type='text'
                  value={skillSearch.query} 
                  placeholder='Select a skill category'
                  onChange={(it) => { searchSkill(it.target.value) }}
                  />
                <ChevronDownIcon className="w-6 h-6 text-grey" />
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              onOpenAutoFocus={(event) => { event.preventDefault() }} 
              onWheel={(e) => e.stopPropagation()}
              align="start" 
              className="w-64 md:w-96 p-0" 
            >
              <Command loop>
                <CommandList className="max-h-fit">
                  <CommandGroup>
                    { skillSearch.queryLower.length == 0 && Object.keys(skillSet).map((category) => (
                      <HoverCard key={category} openDelay={100} closeDelay={0}>
                        <HoverCardTrigger asChild>
                          <CommandItem className="flex items-center justify-between cursor-pointer hover:bg-primary-100">
                            {category}
                            <ChevronRightIcon />
                          </CommandItem>
                        </HoverCardTrigger>
                        <HoverCardContent
                          side="right"
                          align="start"
                          onWheel={(e) => e.stopPropagation()}
                          className='p-0 max-h-64 overflow-y-auto bg-primary-foreground pointer-events-auto no-scrollbar'
                        >
                          <Command>
                              <CommandGroup className='p-0 bg-primary-foreground'>
                                {skillSet[category].map((skill) => {
                                  const skillSelected = consultantSkillSet[category]?.some((s) => s.value === skill.value);
                                  return (
                                    <CommandItem
                                      key={category + "|" + skill.value}
                                      className={cn(
                                        'flex gap-2 px-4 py-2 items-center justify-between bg-primary-foreground cursor-pointer hover:bg-primary-100',
                                        skillSelected && 'bg-primary-100'
                                      )}
                                      value={skill.value}
                                      onSelect={() => updateConsultantSkillSet(category, skill)}
                                    > 
                                      {skill.label}
                                      {skillSelected ? <CheckIcon className='w-4 text-primary-500' /> : null}
                                    </CommandItem>
                                  )
                                })}
                              </CommandGroup>
                          </Command>
                        </HoverCardContent>
                      </HoverCard>
                    ))}
                    { skillSearch.queryLower.length > 0 && filteredSkillSet.map((skill) => {
                          const skillSelected = Object.values(consultantSkillSet).flat()?.some((s) => s.value === skill.value);
                          return (
                            <CommandItem
                              key={skill.category + "|" + skill.value}
                              className={cn(
                                'flex gap-2 px-4 py-2 items-center justify-between bg-primary-foreground cursor-pointer hover:bg-primary-100',
                                skillSelected && 'bg-primary-100'
                              )}
                              value={skill.value}
                              onSelect={() => updateConsultantSkillSet(skill.category, skill)}
                            > 
                              {skill.label}
                              {skillSelected ? <CheckIcon className='w-4 text-primary-500' /> : null}
                            </CommandItem>
                          )
                        }
                      )
                    }
                    { skillSearch.queryLower.length > 0 && 
                        !filteredSkillSet.find((it) => { return it.labelLower == skillSearch.queryLower }) &&
                        createAddSkillItem(Object.values(consultantSkillSet).flat()?.some((s) => s.value === skillSearch.queryLower))
                    }
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        <div className='grid grid-cols-1 md:col-span-2'>
          <div className='grid grid-cols-2 gap-4 md:ml-24'>
            {Object.keys(consultantSkillSet).map((category) => (
              <div key={`category-${category}`}>
                {consultantSkillSet[category].length ? (
                  <div className='flex flex-col gap-2 border-2 rounded-xl p-2 h-full border-primary-500/50'>
                    <p className='text-sm font-medium'>{category}</p>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-2 w-max'>
                      {consultantSkillSet[category].map((skill) => (
                        <Badge key={skill.value} className='flex gap-2 justify-between items-center w-fit max-w-32 md:w-32 h-fit bg-primary-500/90 hover:bg-primary/75'>
                          {skill.label}
                          <XIcon
                            strokeWidth={3}
                            className='w-4 cursor-pointer'
                            onClick={() => updateConsultantSkillSet(category, skill)} />
                        </Badge>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>

        <div className='grid grid-cols-10 md:col-span-2'>
          <div className='flex flex-row items-center col-span-2 md:col-span-1'>
            <label className='font-semibold text-left'>Comments:</label>
          </div>
          <Textarea
            className='col-span-10 md:col-span-9'
            defaultValue={consultantUpdateInfo.comments}
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                comments: e.target.value,
              })
            }
          />
        </div>

        <div className='flex flex-row items-center gap-2'>
          <Checkbox
            id='firstEngagement'
            defaultChecked={consultantUpdateInfo.firstEngagement}
            onCheckedChange={(checked) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                firstEngagement: !!checked.valueOf(),
              })
            }
          />
          <label
            htmlFor='firstEngagement'
            className='peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
          >
            Add "First engagement"
          </label>
        </div>

        <div className='flex flex-row items-center justify-between gap-4 md:col-span-2 '>
          <Button variant={'destructive'} onClick={finalizeDelete}>
            Delete Consultant
          </Button>
          <div className='fixed bottom-0 left-0 flex flex-row items-center justify-center w-full md:w-auto gap-4 p-4 bg-white md:drop-shadow-none md:bg-transparent drop-shadow-[0px_-1px_10px_#00000040] md:static'>
            <Button onClick={saveConsultant}>Save</Button>
            <Button onClick={handleModalOpen} variant={'outline'}>
              Cancel
            </Button>
          </div>
        </div>
        <div className='h-24 md:hidden' />
      </div>
    </div>
  );
}

export default ConsultantCardInfoUpdate;

import React, { useContext } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import Sidebar from './sidebar';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { getCurrentUser } = useContext(CurrentUserContext);

  const isAuthenticated = getCurrentUser();
  if (!isAuthenticated || rest.enabled === false) {
    return <Navigate to='/' />;
  }
  return (
    <div className='flex-row lg:flex'>
      <Sidebar />
      <div
        className={`w-full max-w-screen-xl px-4 ${
          !rest.noPadding && 'py-8'
        } mx-auto`}
      >
        <Component />
      </div>
    </div>
  );
};

export default PrivateRoute;

import React, { useState } from 'react';
import { useOffices } from '../../utils/hooks/api/offices/useOffices';
import { availableLevels, cn } from '../../utils/helpers';
import { Checkbox } from '../ui/checkbox';
import { Consultant, RawConsultant } from '../../types';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Button } from '../ui/button';

function InviteUserModal({
  open,
  handleClose,
  inviteParams,
  setInviteParams,
  onSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  inviteParams: { name: string, email: string; role: string, level?: string, location?: string, firstEngagement?: boolean};
  setInviteParams: (data: { name: string, email: string; role: string, level?: string, location?: string, firstEngagement?: boolean}) => void;
  onSubmit: () => void;
}) {
  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'consultant', label: 'Consultant' },
    { value: 'salesperson', label: 'Salesperson' },
  ];

  const { parsedOffices } = useOffices();

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Invite User</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          {/* TODO Should be a form with validation instead of passing things like this -> React form hook 
                Requires too many changes for this vidual revamp
            */}
          <Input
            type='text'
            value={inviteParams.name}
            placeholder='Name'
            onChange={(e) =>
              setInviteParams({ ...inviteParams, name: e.target.value })
            }
          />  
          <Input
            type='text'
            value={inviteParams.email}
            placeholder='Email'
            onChange={(e) =>
              setInviteParams({ ...inviteParams, email: e.target.value })
            }
          />
          <Select
            value={inviteParams.role}
            onValueChange={(option) =>
              setInviteParams({ ...inviteParams, role: option })
            }
          >
            <SelectTrigger>
              <SelectValue placeholder='Select a role' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {roles.map((role) => {
                  return (
                    <SelectItem key={role.value} value={role.value}>
                      {role.label}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>

          {
            inviteParams.role === 'consultant' && (
              <div className='space-y-4'>

                <Select
                  value={inviteParams.level}
                  onValueChange={(option) => {
                    setInviteParams({ ...inviteParams, level: option });
                  }}
                >
                  <SelectTrigger className='col-span-4'>
                    <SelectValue placeholder='Select level' />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectGroup>
                      {availableLevels.map((color) => {
                        return (
                          <SelectItem key={color.value} value={color.value}>
                            {color.label}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>

                <Select
                  value={inviteParams.location}
                  onValueChange={(option) => {
                    setInviteParams({ ...inviteParams, location: option });
                  }}
                >
                  <SelectTrigger className={cn('col-span-4')}>
                    <SelectValue placeholder='Select location' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {parsedOffices?.map((option) => {
                        return (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>

                <div className='flex flex-row items-center gap-2'>
                  <Checkbox
                    id='firstEngagement'
                    defaultChecked={inviteParams.firstEngagement}
                    onCheckedChange={(checked) =>
                      setInviteParams({ ...inviteParams, firstEngagement: !!checked.valueOf() })
                    }
                  />
                  <label
                    htmlFor='firstEngagement'
                    className='peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                  >
                    First engagement?
                  </label>
                </div>
                
              </div>
            )
          }

          <div className='flex items-center justify-center'>
            <Button onClick={onSubmit}>Invite</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default InviteUserModal;

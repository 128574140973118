import React, { ReactNode, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PieChart, User, Settings, Search } from 'lucide-react';
import { cn } from '../../utils/helpers';
// @ts-ignore
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
// @ts-ignore
import UserService from '../../utils/services/user-service';
import { Button } from '../ui/button';

var navItems: {name: string, path: string, icon: ReactNode}[] = []

interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
  const location = useLocation();

  // @ts-ignore
  const { getAxios, logout, getCurrentUser } = useContext(CurrentUserContext);
  const user = getCurrentUser()

  const navItems = [
    { name: 'Consultants', path: '/consultants', icon: <User /> },
    { name: 'Overview', path: '/overview', icon: <PieChart /> },
    { name: 'Settings', path: '/settings', icon: <Settings /> }
  ]

  const postLogout = async () => {
    await UserService.logout(getAxios());
    const { status } = await logout();
    if (status) {
      // history.push('/login')
    }
  };
  return (
    <>
      <aside className='hidden bg-primary flex-col lg:flex items-center gap-4  min-w-[192px] text-white  min-h-screen'>
        <div className='sticky top-0 flex flex-col justify-between w-full h-screen pt-4 pb-8'>
          <div className='flex flex-col items-center justify-between gap-10'>
            <div className='flex flex-col items-center justify-center'>
              <img
                className='w-16 h-auto'
                src={require('../../assets/images/logo-high-res-tree-only.png')}
              />
              <h4 className='text-primary-100'>Availability</h4>
            </div>
            <div className='flex flex-col items-start flex-1 w-full gap-8 text-xl'>
              { user.role !== 'consultant' && navItems.map((navItem) => (
                <Link
                  key={navItem.name}
                  to={navItem.path}
                  className={cn(
                    'flex items-center justify-start gap-1.5 w-full flex-row py-1.5 pl-4  rounded-r-full',
                    location.pathname === navItem.path &&
                      'text-primary bg-primary-100'
                  )}
                >
                  {navItem.icon}

                  <div>{navItem.name}</div>
                </Link>
              ))}
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <Button variant={'secondary'} onClick={postLogout}>
              Logout
            </Button>
          </div>
        </div>
      </aside>
      <Menu />
    </>
  );
};

interface MenuProps {}
const Menu: React.FC<MenuProps> = ({}) => {
  // @ts-ignore
  const { getAxios, logout, getCurrentUser } = useContext(CurrentUserContext);
  const user = getCurrentUser()

  const postLogout = async () => {
    await UserService.logout(getAxios());
    const { status } = await logout();
    if (status) {
      setOpen(false);
      // history.push('/login')
    }
  };
  const [open, setOpen] = useState(false);
  function handleOpen() {
    setOpen(!open);

    if (open) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }
  return (
    <>
      <div className='w-full h-16 lg:hidden'></div>
      <header
        className={cn(
          'fixed z-50 top-0 left-0 lg:hidden w-full ease-in-out transition-all duration-500 bg-primary text-primary-foreground scrolled shadow  py-2 '
        )}
      >
        <div className='flex flex-row items-center justify-between'>
          <button
            className='z-[51] sticky flex items-center h-8 pl-4 '
            onClick={handleOpen}
          >
            <div
              className='relative items-center justify-center w-6 h-3 '
              id='nav-icon4'
            >
              <span
                className={cn(
                  'block absolute h-0.5 w-full rounded-full transition-all ease-in-out duration-200',
                  open && 'rotate-45  top-1.5',
                  { ' top-0': !open },
                  !open ? 'bg-white' : 'bg-black'
                )}
              ></span>
              <span
                className={cn(
                  'block absolute h-0.5 w-full rounded-full transition-all ease-in-out duration-200 top-1.5',
                  { 'opacity-0 ': open },
                  !open ? 'bg-white' : 'bg-black'
                )}
              ></span>
              <span
                className={cn(
                  'block absolute h-0.5 w-full bg-black rounded-full transition-all ease-in-out duration-200 ',
                  { '-rotate-45  top-1.5': open },
                  { ' top-3': !open },
                  !open ? 'bg-white' : 'bg-black'
                )}
              ></span>
            </div>
          </button>

          <Link to={'/consultants'}>
            <img
              className='w-10 h-auto'
              src={require('../../assets/images/logo-high-res-tree-only.png')}
            />
          </Link>
          <div className='px-4'>{/* <Search /> */}</div>
        </div>

        <div
          className={cn(
            'absolute z-40 bg-black/40 top-0 flex lg:hidden flex-col  left-0 overflow-hidden shadow right-0',
            'transition-all ease-in-out duration-500',
            open ? 'h-screen' : 'h-0'
          )}
        >
          <div
            className={cn(
              'flex flex-col items-center  gap-4 pt-20 pb-4  bg-white text-black ',
              ' '
            )}
          >
            { user.role !== 'consultant' && navItems.map((navItem) => {
              return (
                <Link
                  onClick={handleOpen}
                  key={navItem.name}
                  to={navItem.path}
                  className={cn(
                    'flex items-center justify-start gap-1.5 w-full flex-row py-2 pl-4 ',
                    location.pathname === navItem.path &&
                      'text-primary bg-primary-100'
                  )}
                >
                  {navItem.icon}

                  <div>{navItem.name}</div>
                </Link>
              );
            })}
            <div className='flex items-center justify-center'>
              <Button variant={'secondary'} onClick={postLogout}>
                Logout
              </Button>
            </div>
          </div>

          <div onClick={handleOpen} className='flex-1 bg-black/30 '></div>
        </div>
      </header>
    </>
  );
};

export default Sidebar;

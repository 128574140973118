import React, { useContext, useEffect, useReducer, useState } from 'react';
import ConsultantCard from '../components/consultants-finder-page/consultant-card.component';
import { Trash2, Search, Plus, MoveUp, Info } from 'lucide-react';
// @ts-ignore
import { CurrentUserContext } from '../utils/contexts/current-user.context';
import { Button } from '../components/ui/button';
import { Loader } from '../components/ui/loader';
import { useConsultants } from '../utils/hooks/api/consultants/useConsultants';
import { ConsultantContext } from '../utils/contexts/consultant-context';

function ConsultantSelfPage() {
    // @ts-ignore
    const { getCurrentUser } = useContext(CurrentUserContext);
    const user = getCurrentUser();

    // Modal variables
    const [open, setOpen] = useState(false);
    const handleModalOpen = () => {
    setOpen(!open);
    };

    const { consultants, isLoading, isFetching } = useConsultants({
        searchQuery: "",
        locations: [],
        levels: [],
        colors: [],
        roles: [],
        availabilityDate: undefined
    })

    const consultant = consultants?.at(0)

    const [isConsultantUser, setIsConsultantUser] = useState(true);

    return (
        <div className='flex flex-col w-full h-screen py-8'>
          <Button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className='fixed z-40 rounded-full bottom-4 right-4 md:hidden'
            size={'icon'}
          >
            <MoveUp />
          </Button>
          <div className='flex flex-col justify-between gap-4 md:flex-row'>
            <div className='flex flex-row items-center justify-between'>
              <h1 className='text-primary'>Consultant Data</h1>
              <Button size={'icon'} className='md:hidden' onClick={handleModalOpen}>
                <Plus />
              </Button>
            </div>
          </div>
          <div className='flex flex-col items-center gap-4 md:pt-12 pb-4 overflow-y-scroll'>
            <Loader loading={isLoading} />
            {
                consultant && (
                    <ConsultantContext.Provider
                    key={user.id}
                    value={{ consultant, isConsultantUser }}
                    >
                    <ConsultantCard />
                    </ConsultantContext.Provider>
                )
            }
            {!consultant && 'No Consultants Found'}
          </div>
        </div>
      );
}

export default ConsultantSelfPage